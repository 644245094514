<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, downloadAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'

export default {
  name: 'mainBack',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmShopApply/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '追溯主体名',
          key: 'firmName',
          type: 'input'
        },
        {
          name: '联系电话',
          key: 'phone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'firmName',
          title: '追溯主体名',
          align: 'left',
          sorter: (a, b) => a.mobile - b.mobile
        },
        {
          dataIndex: 'phone',
          title: '联系电话',
          align: 'left'
        },
        {
          dataIndex: 'productNames',
          title: '上架意向',
          align: 'left',
          customRender: text => <span>{text && text.length > 40 ? text.substring(0, 40) + '...' : text}</span>
        },
        {
          dataIndex: 'source',
          title: '来源',
          align: 'left',
          filters: [
            {
              text: '监管系统',
              value: '0'
            },
            {
              text: '农起帮',
              value: '1'
            },
            {
              text: '商家端',
              value: '2'
            },
            {
              text: '共享冷库',
              value: '3'
            }
          ],
          filterMultiple: false,
          onExport: text => {
            return ['监管系统', '农起帮', '商家端', '共享冷库'][text]
          },
          customRender: text => {
            return ['监管系统', '农起帮', '商家端', '共享冷库'][text]
          }
        },
        {
          dataIndex: 'status',
          title: '关联情况',
          type: 'badge',
          filters: [
            {
              text: '完善主体',
              value: '0'
            },
            {
              text: '完善店铺',
              value: '1'
            },
            {
              text: '待开通',
              value: '2'
            },
            {
              text: '已关联',
              value: '3'
            }
          ],
          filterMultiple: false,
          onExport: text => {
            return {
              '0': '完善主体',
              '1': '完善店铺',
              '2': '待开通',
              '3': '已关联'
            }[text]
          },
          render(data) {
            return {
              showDot: true,
              name: {
                '0': '完善主体',
                '1': '完善店铺',
                '2': '待开通',
                '3': '已关联'
              }[data],
              color: {
                '0': 'red',
                '1': 'red',
                '2': 'green',
                '3': 'green'
              }[data]
            }
          }
        },
        {
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                is_show: records.status == 2,
                name: '开通店铺',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/farmShopApply/execOpen?id=${records.id}`
                  })
                }
              },
              {
                is_show: true,
                name: {
                  '0': '完善主体',
                  '1': '完善店铺',
                  '2': '完善店铺',
                  '3': '关闭店铺'
                }[records.status],
                onClick: () => {
                  switch (Number(records.status)) {
                    case 0:
                      this.$router.push('/audit/mainBackDetail?id=' + records.id)
                      break
                    case 1:
                      this.$router.push(
                        `/commodityManagement/entityInformationDetail?id=${records.shopId}&shopApplyId=${records.id}&firmId=${records.firmId}&firmName=${records.firmName}`
                      )
                      break
                    case 2:
                      this.$router.push(`/commodityManagement/entityInformationDetail?id=${records.shopId}`)
                      break
                    default:
                      api.command.editGET.call(this, {
                        url: `/farmShopApply/execCancel?id=${records.id}`
                      })
                      break
                  }
                }
              }
            ].filter(e => e.is_show)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/audit/farmAuditFeedback/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    },
    getDrawerForm(records) {
      const form = [
        {
          title: '类别',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '类型',
                  value: ['企业', '个体工商户'][records.authType],
                  width: '100%'
                }
              ]
            }
          ]
        },
        {
          title: '企业',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '企业名称',
                  value: records.companyName,
                  width: '100%'
                },
                {
                  name: '证件类型',
                  value: {
                    U: '营业执照',
                    Y: '组织机构代码证',
                    V: '统一社会信用代码'
                  }[records.companyCertType],
                  width: '100%'
                },
                {
                  name: '证件号码',
                  value: records.companyCertNo,
                  width: '100%'
                }
              ]
            },
            {
              type: 'imageGroup',
              value: records.subMerCertFileOne
            }
          ]
        },

        {
          title: '身份证',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '姓   名',
                  value: records.contactName,
                  width: '100%'
                },
                {
                  name: '身份证',
                  value: records.companyCertNo,
                  width: '100%'
                }
              ]
            },
            {
              type: 'imageGroup',
              value: records.subMerCertFileTwo
            }
          ]
        },
        {
          title: '结算',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '收款银行',
                  value: records.bankName,
                  width: '100%'
                },
                {
                  name: '账户名称',
                  value: records.accountName,
                  width: '100%'
                },
                {
                  name: '账户号码',
                  value: records.account,
                  width: '100%'
                },
                {
                  name: '预留手机号',
                  value: records.mobilePhone,
                  width: '100%'
                }
              ]
            },
            {
              type: 'imageGroup',
              value: records.subMerCertFileThree
            }
          ]
        }
      ]
      return form
    },
    onSelectRow(records) {
      getAction('/farmShopApply/getRegisterShopInfo?id=' + records.id).then(res => {
        const data = this.getDrawerForm(res.data)
        apiTool.showDrawer({
          title: '已上传资料',
          width: '700px',
          view: DrawerForm,
          viewProps: { data },
          success: ({ setHidden }) => {
            let {
              subMerCertFileOneName,
              subMerCertFileTwoName,
              subMerCertFileThreeName,
              subMerCertFileOne,
              subMerCertFileTwo,
              subMerCertFileThree
            } = res.data
            if (subMerCertFileOne) {
              downloadAction(`/api/farmShopApply/downZip?id=${records.id}&type=${1}`, subMerCertFileOneName + '.zip')
            }
            if (subMerCertFileTwo) {
              downloadAction(`/api/farmShopApply/downZip?id=${records.id}&type=${2}`, subMerCertFileTwoName + '.zip')
            }
            if (subMerCertFileThree) {
              downloadAction(`/api/farmShopApply/downZip?id=${records.id}&type=${3}`, subMerCertFileThreeName + '.zip')
            }
          },
          foot: ({ close, submit }) => {
            return [
              {
                name: '取消',
                onClick: close
              },
              {
                name: '下载文件',
                type: 'primary',
                loading: false,
                onClick: submit
              }
            ]
          }
        })
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onSelectRow}
      />
    )
  }
}
</script>
